import React from 'react';

const LinkComponent = (props) => {
  return (
    <>
    <div className="flex space-x-3">
        <a
          href={props.url}
          target="_blank"
          className="hover:text-blue-300"
        >
          <i className={props.icon}></i>
          {props.text}
        </a>
      </div>
        </>
  );
};

export default LinkComponent;
