import React from 'react';
import pageBySlugService from '../../features/pages/pageBySlug';
import { useParams } from 'react-router-dom';
import TextComponent from '../../components/page/TextComponent';
import ImageComponent from '../../components/page/ImageComponent';
import LinkComponent from '../../components/page/LinkComponent';
import { useState } from 'react';
import TitleComponent from '../../components/page/TitleComponent';

function updatePageContent(slug, components, setComponents) {
  if (components.length > 0) {
    return
  }
  pageBySlugService.getPageBySlug(slug).then((res) => {
    let pageContent = [];
    console.log(res)
      res.forEach(element => {
        switch(element.type) {
          case 'text':
            pageContent.push(<TextComponent text={element.data.text} />)
            break;
          case 'image':
            pageContent.push(<ImageComponent url={element.data.url} alt={element.data.alt} />)
            break;
          case 'url':
            pageContent.push(<LinkComponent icon={element.data.icon} text={element.data.text} url={element.data.url} />)
            break;
          case 'header':
              pageContent.push(<TitleComponent text={element.data.text} />)
              break;
          default:
            console.log('Error: Unknown type: ' + element.type)
            break;
        }
      })
      setComponents(pageContent)
  })
}

const ViewPageSlug = () => {
    let { slug } = useParams();
    const [components, setComponents] = useState([]);

    updatePageContent(slug, components, setComponents)
    
  return (
    <div className="text-white max-w-3xl flex flex-col space-y-3">
      {components.length > 0 ? components.map(component => component) : 'Loading...'}
    </div>
  );
};

export default ViewPageSlug;
