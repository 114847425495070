import { client } from '../../utils/config';

const getPageBySlug = async (slug) => {
  try {
    const res = await client.collection('userpage_content').getFullList({filter: 'userpage.slug = "'+slug+'"', sort: 'position'})

    return res;
  } catch (err) {
    return err;
  }
};
const getPageByUser = async (user) => {
  try {
    const res = await client.collection('userpage').getFullList({filter: 'user.username = "'+user+'"'})
    console.log(res);
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const pageBySlugService = {
    getPageBySlug,
    getPageByUser
};

export default pageBySlugService;
