import Pocketbase from 'pocketbase';

const url = 'https://api.furtag.net';
const client = new Pocketbase(url);
client.autoCancellation(false);
client.beforeSend = function (url, options) {
    // For list of the possible request options properties check
    // https://developer.mozilla.org/en-US/docs/Web/API/fetch#options
    options.headers = Object.assign({}, options.headers, {
        'x-tech-dev': 'true',
    });

    return { url, options };
};

const redirectUrl = 'https://furtag.net/redirect'

export { client, redirectUrl };
