import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../features/auth/authSlice';
import ButtonComponent from '../components/ButtonComponent';
import pageBySlugService from '../features/pages/pageBySlug';
import { useState } from 'react';

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [tags, setTags] = useState([]);
  const [pages, setPages] = useState(undefined);
  if (pages === undefined) {
  pageBySlugService.getPageByUser(user?.meta?.username).then((res) => {
    setPages(res.map((page) => {
      return page.slug;
    }));
  }).catch((res) => {
    setPages([]);
  });
}

  return (
    <>
    <div className="text-white flex items-center border rounded-lg p-5 space-x-10 my-2.5">
      <div className="space-y-1">
        <p className="text-lg">
          Welcome, <span className="text-lime-400">{user?.meta?.name}</span>
        </p>
        <Link to="/">
          <p className="text-lime-400 my-1">Back to home</p>
        </Link>
        <ButtonComponent url="/" text="Logout" onClick={() => dispatch(logout())} />
      </div>
    </div>
    <div className="text-white flex items-center border rounded-lg p-5 space-x-10 my-2.5">
        <div className="space-y-1">
          <p className="text-lg">
            Tags
          </p>
          {tags.length > 0 ? tags.map((page) => (
              <div className="space-y-1">
              <Link to={`/p/${page.slug}`} key={page.id}>
                <p className="text-lime-400 my-1">{page.title}</p>
              </Link>
              </div>
            )): "Loading..."}
        </div>
      </div>
      <div className="text-white flex items-center border rounded-lg p-5 space-x-10 my-2.5">
        <div className="space-y-1">
          <p className="text-lg">
            Pages
          </p>
            {(pages !== undefined && pages.length > 0) ? pages.map((page) => (
              <div className="space-y-1">
                <p className="text-lime-400 my-1"><Link to={`/p/${page}`} key={page}>{page}</Link> - <Link to={`/p/${page}/edit`} 
                key={`${page}-edit`}>(edit)</Link></p>
              </div>
            )): "Loading..."}
        </div>
      </div>
      </>
  );
};

export default ProfilePage;
