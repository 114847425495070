import React from 'react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ViewPageSlug from './ViewPageSlug';


const EditPageSlug = () => {
    let { slug } = useParams();
    const { user } = useSelector((state) => state.auth);
    // TODO if user is not owner of this page, redirect to the page instead

    
  return (
    <ViewPageSlug/>
  );
};

export default EditPageSlug;
