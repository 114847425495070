import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ButtonComponent from '../components/ButtonComponent';

const HomePage = () => {
  const { loggedIn, user } = useSelector((state) => state.auth);

  return (
    <div className="text-white max-w-3xl flex flex-col space-y-3">
      <h1 className="text-xl">Furtag</h1>
      <p>
        <span
          className={`
          ${loggedIn ? 'text-green-400' : 'text-red-400'}
        `}
        >
          {loggedIn ? `Welcome, ${user?.meta?.name}` : 'Not logged in'}
        </span>
      </p>

      <code className="bg-gray-600 p-1 text-lime-400">
        Furtag is the tag for your badge. You can also create your own social link page.
      </code>{loggedIn ? (
      <ButtonComponent url="/profile" text="Profile" />
      ) : (
        <ButtonComponent url="/login" text="Login" />
      )}
      <hr />
      <div className="flex space-x-3">
        <a
          href="https://furrylogin.net"
          target="_blank"
          className="hover:text-blue-300"
        >
          <i className="fas fa-paw pr-1"></i>
          Powered by FurryLogin.net
        </a>
      </div>
    </div>
  );
};

export default HomePage;
