import React from 'react';

const TextComponent = (props) => {
  return (
    <code className="bg-gray-600 p-1 text-lime-400">
    {props.text}
  </code>
  );
};

export default TextComponent;
